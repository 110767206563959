html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  max-width: 800px;
  margin: 0 auto;
  background-color: rgb(1, 24, 3);
  padding-top: 20px;
}

body, button, input {
  color: rgb(55, 255, 0);
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}
